// Sticky footer support
body,
#__next,
div[data-reactroot],
.layout {
  display: flex;
  flex-direction: column;
}

#__next {
  // https://trusted-health.atlassian.net/browse/AA-485
  min-height: 100%;
}
