// ============================================================================
// COLOR DECLARATIONS
// These colors also appear in `src/support/colors.ts`. Do not add
// new colors that are not present in that file.
// ============================================================================

$black: #000000;
$white: #FFFFFF;
$primary-01: #49E1BB;
$accent-01-lower: #CDF4EA;
$accent-01-low: #A1E5D4;
$accent-01: #31FBC8;
$accent-03-low: #FFF4CF;
$neutral-low: #EDEDED;
$neutral-default: #E4E2DE;
$neutral-high: #B2B1B6;
$neutral-higher: #818181;
$neutral-highest: #5E5C5C;
$neutral-highest-1: #323135;

// ==================
// SEMANTIC
// ==================

// Error
$semantic-error-lower: #FFF5F5;
$semantic-error-low: #FFDEDE;
$semantic-error: #FF6B6B;
$semantic-error-high: #C92A2A;
$semantic-error-higher: #992020;

// Warning
$semantic-warning-lower: #FFF8F0;

// Bulma variables
$turquoise: $neutral-highest-1;
$blue: $neutral-highest-1;
$red: $semantic-error;
$primary: $primary-01;

// Social Media
$instagram: #fb3958;
$facebook: #4b69ac;
$twitter: #00aced;
$blog: #e5ae00;

.is-error {
  color: $semantic-error-high !important;
}

a.is-error:hover {
  color: $semantic-error !important;
}

.is-accent-low {
  color: $accent-01-low !important;
}

.is-neutral {
  color: $neutral-highest-1 !important;
}

a.is-neutral:hover {
  color: $neutral-highest !important;
}

.is-neutral-highest {
  color: $neutral-highest !important;
}

.is-black {
  color: $black;
}

.is-accent-03-low-bg {
  background-color: $accent-03-low;
}

.is-instagram-color {
  color: $instagram;
}

.is-facebook-color {
  color: $facebook;
}

.is-twitter-color {
  color: $twitter;
}

.is-blog-color {
  color: $blog;
}
