/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Variables
    ## Colors
    ## Structure
    ## Elements
    ## Select
    ## Navbar
# Bulma Imports
# Layout
    ## Content
# Typography
    ## Headings
# Forms
    ## Buttons
# Components
    ## Modal
    ## Select
# Global
    ## Footer
    ## Print Profile
*/

/*----------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
@import 'variables/breakpoints';
@import 'variables/colors';
@import 'variables/structure';
@import 'variables/elements';

/*--------------------------------------------------------------
# External Imports
--------------------------------------------------------------*/
@import 'thirdparty/nprogress';
@import 'bulma/bulma';
@import '@fortawesome/fontawesome-svg-core/styles';

/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
@import 'layout/content';

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import 'typography/fonts';
@import 'typography/headings';

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@import 'elements/link';

@import 'helpers';
@import 'utility';

/*--------------------------------------------------------------
# Global
--------------------------------------------------------------*/
@import 'global/footer';
@import 'global/button';

html {
  overflow-y: auto; // Removes judder when Material UI select boxes appear
  overscroll-behavior-y: none;
}
