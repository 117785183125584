/* Bridge to a utility-style approach to CSS (ala Tailwind CSS) */

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

// .leading-3 {
//   /* 12px */
//   line-height: .75rem;
// }

// .leading-4 {
//   /* 16px */
//   line-height: 1rem;
// }

// .leading-5 {
//   /* 20px */
//   line-height: 1.25rem;
// }

.leading-6 {
  /* 24px */
  line-height: 1.5rem;
}

// .leading-7 {
//   /* 28px */
//   line-height: 1.75rem;
// }

// .leading-8 {
//   /* 32px */
//   line-height: 2rem;
// }

// .leading-9 {
//   /* 36px */
//   line-height: 2.25rem;
// }

// .leading-10 {
//   /* 40px */
//   line-height: 2.5rem;
// }

// .leading-none {
//   line-height: 1;
// }

// .leading-tight {
//   line-height: 1.25;
// }

// .leading-snug {
//   line-height: 1.375;
// }

// .leading-normal {
//   line-height: 1.5;
// }

// .leading-relaxed {
//   line-height: 1.625;
// }

// .leading-loose {
//   line-height: 2;
// }

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-item {
  display: list-item;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.list-inside {
  list-style-position: inside;
}

.list-none {
  list-style-type: none;
}

.text-base {
  /* 16px */
  font-size: 1rem;
  /* 24px */
  line-height: 1.5rem;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  /* 24px */
  margin-top: 1.5rem;
}

.text-lg {
  /* 18px */
  font-size: 1.125rem;
  /* 28px */
  line-height: 1.75rem;
}

.text-xl {
  /* 24px */
  font-size: 1.5rem;
  /* 29.72px */
  line-height: 1.8575rem;
}

.text-2xl {
  /* 32px */
  font-size: 2rem;
  line-height: normal;
}

// Extracted from Ligament https://github.com/bananatron/ligament/
@media (min-width: 0px) {
  .xs-mb2 {
    margin-bottom: 1rem !important;
  }

  .xs-mb4 {
    margin-bottom: 2rem !important;
  }

  .xs-mr1 {
    margin-right: 0.5rem !important;
  }

  .xs-mt2 {
    margin-top: 1rem !important;
  }

  .xs-p2 {
    padding: 1rem !important;
  }

  .xs-p3 {
    padding: 1.5rem !important;
  }

  .xs-p4 {
    padding: 2rem !important;
  }
}

@media (min-width: 767px) {
  .sm-hide {
    display: none !important;
  }

  .sm-mt6 {
    margin-top: 3rem !important;
  }
}
