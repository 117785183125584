@import 'variables/colors';

img {
  flex-shrink: 0;
}

html {
  height: 100%;
  margin: 0;
}

.full-width {
  width: 100%;
}

.full-height {
  min-height: 100%;
}

.columns {
  margin-right: 0;
  margin-left: 0;

  .column .side-column {
    max-width: 180px;
    font-weight: bold;
  }
}

.hide {
  display: none !important;
}

.section {
  padding: 0;
}

.layout {
  background: $white;
}

ol>li {
  margin-left: 2rem;
}

.is-size-7 {
  font-size: 0.85rem !important;
}

.is-size-8 {
  font-size: 0.75rem !important;
}

.menu-autocomplete {
  background-color: $white;
  border: 1px solid rgb(85, 85, 85);
  top: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  overflow: auto;
}

.item-autocomplete {
  background-color: $white;
  padding: 10px;
  color: rgb(85, 85, 85);
  cursor: pointer;
}

.item-autocomplete-highlighted {
  background-color: rgb(250, 250, 250);
  padding: 10px;
  color: rgb(85, 85, 85);
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.markdown {
  h1 {
    font-size: 2rem;
    margin-top: 0.67rem;
    margin-bottom: 0.67rem;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 0.83rem;
    margin-bottom: 0.83rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.17rem;
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
  }

  h4 {
    font-size: 1rem;
    margin: 1.33rem 0;
    font-weight: bold;
  }

  p {
    margin: 1rem 0;
    margin-top: 0;
  }

  ul {
    list-style: disc;
    padding-left: 40px;
    margin: 1rem 0;
  }
}

.hint {
  color: $neutral-highest;

  font-size: 14px;

  strong {
    color: $neutral-highest;
  }

  &.hint--small {
    font-size: 12px;
  }
}

.box-hint {
  background-color: $semantic-warning-lower;
  color: $neutral-highest;
  font-size: 0.825rem;
  border-radius: 4px;
  padding: 1rem;
}

.box-error {
  background-color: $semantic-error-lower;
  color: $neutral-highest;
  font-size: 0.825rem;
  border-radius: 4px;
  padding: 1rem;
  display: flex;

  .box-icon {
    color: $semantic-error;
  }
}

.box-icon {
  margin-right: 10px;
  font-size: 20px;
}

.delete-action {
  font-size: 16px;
  color: $neutral-highest;
  font-weight: 600;

  &:hover {
    color: $semantic-error;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::selection {
  color: $black;
  background: $accent-01-lower;
}

.is-reverse-mobile {
  @media only screen and (max-width: $mobile-max-width) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.text-badge {
  border: 1px solid $neutral-highest;
  border-radius: 40px;
  background-color: white;

  color: $black;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0.3rem 0.75rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-right: 0;
  }
}

.important {
  color: $semantic-error;
}

.self-center {
  /* @see https://developer.mozilla.org/docs/Web/CSS/align-self */
  align-self: center;
}
