.no-underline {
  /* @see https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration-line */
  text-decoration-line: none;
}

.underline {
  /* @see https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration-line */
  text-decoration-line: underline;
}

a, a:hover {
  color: $black
}

p>a {
  text-decoration: underline;
}
